import React ,{useEffect, useState} from 'react';
import { CSSTransitionGroup } from 'react-transition-group'

import { useMediaQuery } from 'react-responsive'
import '../../styles/index.css';
import boxMainImmage from '../../images/new_images/front-direct-image-1.png';
import boxSidePotraite from '../../images/side-potrait.png';
import boxFront from '../../images/front-strieght.png';
import boxFrontSided from '../../images/front-sided.png';
// import boxBack from '../../images/back-striaght.png';
// import boxBackSided from '../../images/back-sided.png';
import boxBackSided from '../../images/new_images/back-side.png';
import boxLoadSupport from '../../images/new_images/load_support.png';
import boxBackSidedNew from '../../images/new_images/back_sided-new.png';
import boxSided from '../../images/new_images/sided_new.png';

import lhgp_Logo from '../../images/new_images/lhgp_logo.jpeg';
import afritech_Logo from '../../images/new_images/afritech_logo.png';



import boxFrontFacingPowerBtn from '../../images/new_images/front_sided_power_btn_focus.png';
import Icons from '../icons';
import ReactGA from 'react-ga';






export function Header(){
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 800px)'
      })

    return(
        <div style={{
            height:'50px',position:'fixed',left:'0px',right:'0px',display:'flex',flexDirection:'row',justifyContent:'space-between',
            alignItems:'center',zIndex:1000,overflow:'hidden'
            }}>

            <div style={{paddingLeft:'15px',color:'green'}}>
                {(isDesktopOrLaptop)?
                <Icons icon="logo" size="180" color="#429a37"/>:
                <Icons icon="logo" size="140" color="#429a37"/>}
            </div>
            
            <div style={{}}></div>
        </div>
    )
} 


export function TopStage(){

    const [showTopBuy, setShowTopBuy] = useState(false);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 800px)'
      })

      function openTopBuy(){
        setShowTopBuy(true);
      }

      const openWhatsapp = () => {
        window.open("https://wa.me/+2349070811425?text=Hi", "_blank");
        ReactGA.pageview("whatsapp clicked");
      }


    return(
    <div style={{backgroundColor:'#000000',width:'100%',display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center'}}>

    {(isDesktopOrLaptop)?
     <div className="H1_pc">Enjoy 24 Hours Power</div>
     :
     <div className="H1" style={{fontSize:'30px',textAlign:'center'}}>Enjoy 24 Hours Power</div>
     }

     {(isDesktopOrLaptop)?
     <div className="H2_pc">Your All New Dependable Power Source</div>
     :
     <div className="H2" style={{fontSize:'14px',textAlign:'center'}}>Your All New Dependable Power Source</div>
     }


     {(isDesktopOrLaptop)?
     <div style={{}} className="H3_pc">For Homes | Offices | Small Businesses</div>
     :
     <div  className="H3" style={{fontSize:'13px',textAlign:'center'}}>For Homes | Offices | Small Businesses</div>
     }

     {(isDesktopOrLaptop)?
     <div style={{}}>
        <div onClick={openWhatsapp} style={{backgroundColor:'#FFFFFF',padding:'15px',borderRadius:'5px',marginTop:0,padding:'5px 20px 5px'}}>
            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',color:'#000000',fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:300,}}>
                <div style={{color:'#1A4520'}}>Click here to chat with us on whatsapp</div>
                <div style={{fontSize:'32px',fontWeight:500}}>09070811425</div>
            </div>
        </div>
     </div>:

     <div style={{}}>       
        <div onClick={openWhatsapp} style={{backgroundColor:'#FFFFFF',padding:'8px 20px 5px',borderRadius:'5px',marginTop:10,marginBottom:20}}>
            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',color:'#000000',fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:300,}}>
                <div style={{fontWeight:'light',color:'#1A4520'}}>Click here to chat with us on whatsapp</div>
                <div style={{fontSize:'35px',fontWeight:500}}>09070811425</div>
            </div>
        </div>
     </div>}



     {(isDesktopOrLaptop)?
     <div style={{padding:'20px 0px 0px',width:'100%',display:'flex',justifyContent:'center',alignItems:'flex-end'}} className="">
         <img src={boxMainImmage} style={{maxWidth:'700px',marginTop:-20}}/>
     </div>
     :
     <div style={{padding:'20px 0px 0px',display:'flex',justifyContent:'center',alignItems:'center',width:'100%'}} className="">
         <img src={boxMainImmage} style={{width:'98%',maxWidth:'320px',marginTop:-20}}/>
     </div>
     }

    </div>)
}


export function SecondStagePc(){

    return(
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',paddingTop:'60px',paddingBottom:'60px'}}>
            
            <div style={{width:'1150px',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                <div style={{flex:1}}>
                    
                    
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'flex-start',margin:'30px 0px 50px'}}>
                        <div className="sub2PcCircles design_icon" style={{borderRadius:'100px'}}>
                           {/* <Icons icon="design" size="35" color="#FFFFFF"/> */}
                           <img src={require('../../images/new_images/designer_icon.png')} style={{width:40,height:40}}/>
                        </div>
                        
                        <div style={{flex:1}}>
                            <div style={{}} className="sub2PcH1">Clean Design</div>
                            <div style={{}} className="sub2PcH2">The powerbox is aesthetically built to look great in-home.</div>
                        </div>

                    </div>



                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'flex-start',margin:'30px 0px 50px'}}>
                        <div className="sub2PcCircles design_icon2" style={{justifyContent:'center',alignItems:'center',display:'flex',borderRadius:'100px'}}>
                            <img src={require('../../images/new_images/arm.png')} style={{width:45,height:45}}/>
                        </div>
                        
                        <div style={{flex:1}}>
                            <div style={{}} className="sub2PcH1">Rigid and Durable</div>
                            <div style={{}} className="sub2PcH2">Manufactured from high grade steel, the Powerbox will withstand extreme environments without damage.</div>
                        </div>
                        

                    </div>




                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'flex-start',margin:'30px 0px 50px'}}>
                        <div className="sub2PcCircles design_icon3" style={{justifyContent:'center',alignItems:'center',display:'flex',borderRadius:'100px'}}>
                            <img src={require('../../images/new_images/speed.png')} style={{width:45,height:45}}/>
                        </div>
                        
                        <div style={{flex:1}}>
                            <div style={{}} className="sub2PcH1">Recharge Speed</div>
                            <div style={{}} className="sub2PcH2">Powerbox offers you the fastest recharge. You do not need to worry anymore about days with low sunlight.</div>
                        </div>

                         

                    </div>


                    


                    

                </div>

                <div style={{flex:0.9,justifyContent:'center',alignItems:'center',display:'flex'}}>
                    <img src={require('../../images/new_images/sided_pc.png')} style={{maxWidth:'420px',marginBottom:-2}}/>
                </div>

                <div style={{flex:1,background:'white'}}>

                    
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'flex-start',margin:'30px 0px 50px'}}>
                        <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-end',}}>
                            <div style={{}} className="sub2PcH1"> The Bundle</div>
                            <div style={{textAlign:'right'}} className="sub2PcH2">The powerbox bundle comes with two high powered solar panels and the unit.</div>
                        </div>

                        <div className="sub2PcCircles2" style={{
                            justifyContent:'center',alignItems:'center',display:'flex',
                            backgroundColor:'#FF3F2C',borderRadius:'100px'}}>
                                <img src={require('../../images/new_images/solar-energy.png')} style={{width:45,height:45}}/>
                        </div>
                    </div>



                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'flex-start',margin:'30px 0px 50px'}}>
                        <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-end',}}>
                            <div style={{}} className="sub2PcH1">Power Availabity</div>
                            <div style={{textAlign:'right'}} className="sub2PcH2">Powerbox provides all-day power and over 10hrs power supply at night!</div>
                        </div>

                        <div className="sub2PcCircles2" style={{
                            justifyContent:'center',alignItems:'center',display:'flex',
                            backgroundColor:'#0071E2',borderRadius:'100px'}}>
                                <img src={require('../../images/new_images/energetic.png')} style={{width:45,height:45}}/>
                            </div>
                    </div>



                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'flex-start',margin:'30px 0px 50px'}}>
                        <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-end',}}>
                            <div style={{}} className="sub2PcH1">Portability</div>
                            <div style={{textAlign:'right'}} className="sub2PcH2">Get power everywhere, anywhere. It’s not just for your home but for your medium-powered appliances. It’s the perfect package.</div>
                        </div>

                        <div className="sub2PcCircles2" style={{
                            justifyContent:'center',alignItems:'center',display:'flex',
                            backgroundColor:'#E5AAFA',borderRadius:'100px'}}>
                                <img src={require('../../images/new_images/pinch.png')} style={{width:45,height:45}}/>
                        </div>
                    </div>





                    {/* <div style={{}}>4</div>
                    <div style={{}}>5</div>
                    <div style={{}}>6</div> */}
                </div>

            </div>

        </div>
    )
}






export function SecondStageMobile(){

    return(
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',padding:'10px 5px 0px'}}>
            
            <div style={{width:'100%',display:'flex',flexDirection:'column'}}>
                <div style={{flex:1}}>
                    
                    
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'flex-start',margin:'30px 0px 50px'}}>
                        <div className="sub2PcCircles design_icon" style={{borderRadius:'100px',}}>
                           <img src={require('../../images/new_images/designer_icon.png')} style={{width:40,height:40}}/>
                        </div>
                        
                        <div style={{flex:1}}>
                            <div style={{}} className="sub2PcH1">Clean Design</div>
                            <div style={{}} className="sub2PcH2">The powerbox is aesthetically built to look great in-home.</div>
                        </div>

                    </div>



                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'flex-start',margin:'30px 0px 50px'}}>
                        <div className="sub2PcCircles design_icon2" style={{justifyContent:'center',alignItems:'center',display:'flex',backgroundColor:'#FAA44',borderRadius:'100px'}}>
                                <img src={require('../../images/new_images/arm.png')} style={{width:45,height:45}}/>
                            </div>
                        
                        <div style={{flex:1}}>
                            <div style={{}} className="sub2PcH1">Rigid and Durable</div>
                            <div style={{}} className="sub2PcH2">Manufactured from high grade steel, the Powerbox will withstand extreme environments without damage.</div>
                        </div>
                        

                    </div>




                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'flex-start',margin:'30px 0px 50px'}}>
                        <div className="sub2PcCircles design_icon3" style={{justifyContent:'center',alignItems:'center',display:'flex',borderRadius:'100px'}}>
                            <img src={require('../../images/new_images/speed.png')} style={{width:45,height:45}}/>
                        </div>
                        
                        <div style={{flex:1}}>
                            <div style={{}} className="sub2PcH1">Recharge Speed</div>
                            <div style={{}} className="sub2PcH2">Powerbox offers you the fastest recharge. You do not need to worry anymore about days with low sunlight.</div>
                        </div>

                    </div>


                </div>


                <div style={{flex:1}}>

                    
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'flex-start',margin:'0px 0px 50px'}}>
                        <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-end',}}>
                            <div style={{}} className="sub2PcH1">The Bundle</div>
                            <div style={{textAlign:'right'}} className="sub2PcH2">The powerbox bundle comes with two high powered solar panels and the unit.</div>
                        </div>

                        <div className="sub2PcCircles2" style={{
                            justifyContent:'center',alignItems:'center',display:'flex',
                            backgroundColor:'#FF3F2C',borderRadius:'100px'}}>
                                <img src={require('../../images/new_images/solar-energy.png')} style={{width:45,height:45}}/>

                            </div>
                    </div>



                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'flex-start',margin:'30px 0px 50px'}}>
                        <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-end',}}>
                            <div style={{}} className="sub2PcH1">Power Availabity</div>
                            <div style={{textAlign:'right'}} className="sub2PcH2">Powerbox provides all-day power and over 10hrs power supply at night!</div>
                        </div>

                        <div className="sub2PcCircles2" style={{
                            justifyContent:'center',alignItems:'center',display:'flex',
                            backgroundColor:'#0071E2',borderRadius:'100px'}}>
                                {/* <Icons icon="electric" size="35" color="#FFFFFF"/> */}
                                <img src={require('../../images/new_images/energetic.png')} style={{width:45,height:45}}/>

                            </div>
                    </div>



                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'flex-start',margin:'30px 0px 50px'}}>
                        <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-end',}}>
                            <div style={{}} className="sub2PcH1">Portability</div>
                            <div style={{textAlign:'right'}} className="sub2PcH2">Get power everywhere, anywhere. It’s not just for your home but for your medium-powered appliances. It’s the perfect package.</div>
                        </div>

                        <div className="sub2PcCircles2" style={{
                            justifyContent:'center',alignItems:'center',display:'flex',
                            backgroundColor:'#E5AAFA',borderRadius:'100px'}}>
                                <img src={require('../../images/new_images/pinch.png')} style={{width:45,height:45}}/>

                            </div>
                    </div>

                </div>

            </div>

        </div>
    )
}









export function ThirdStagePc(){
    return(
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'row'}}>

            <div style={{padding:'10px',display:'flex',flexDirection:'row',backgroundColor:'#FFFFFF',maxWidth:'1600px',flex:1}}>




                    <div style={{padding:'10px',display:'flex',flexDirection:'column',backgroundColor:'#FFFFFF',maxWidth:'1600px',flex:1}}>

                                    
                                        
                        <div style={{border:'1px solid #F1F1F1',backgroundColor:'#F4F4F4',marginBottom:'0px',display:'flex',flexDirection:'column',borderRadius:'1px',borderRadius:5,overflow:'hidden'}}>


                            <div style={{}}>
                                <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',paddingRight:'15px',padding:'10px 0px 0px 20px'}}>
                                   
                                    <div style={{fontSize:'35px',fontFamily:"'Roboto', sans-serif",fontWeight:300,padding:'5px 0px 0px'}}>
                                        Robust Design
                                    </div>
                                    <div style={{fontSize:'25px',fontFamily:"'Roboto', sans-serif",fontWeight:300,}}>
                                        Carefully built out of simplicity.
                                    </div>

                                </div>
                            </div>



                            <div style={{display:'flex',justifyContent:'center',alignItems:'center',flex:1,overflow:'hidden',marginTop:0}}>
                                <img src={boxFrontFacingPowerBtn} style={{width:'100%'}}/>
                            </div>



                        </div>



                    </div>





                <div 
                    style={{
                        border:'1px solid #FCFCFC',backgroundColor:'#FAFAFA',width:'300px',height:'650px',
                        flex:1,marginLeft:'5px',display:'flex',flexDirection:'column',marginTop:8,borderRadius:5}}>

                    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            <div style={{fontSize:'40px',fontFamily:"'Roboto', sans-serif",fontWeight:300,padding:'15px 0px 0px'}}>Easy to install</div>
                            <div style={{fontSize:'25px',fontFamily:"'Roboto', sans-serif",fontWeight:300,}}>Without special technical assistance</div> 
                        </div>
                    </div>

                    <div style={{flex:1,display:'flex',justifyContent:'center',alignItems:'flex-end',flexDirection:'column'}}>
                        <div style={{marginBottom:'0px',flex:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <img src={boxBackSidedNew} style={{width:'100%'}}/>
                        </div>
                    </div>

                </div>



            </div>
        </div>
    )

}










export function ThirdStageMobile(){
    return(
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'row'}}>

            
            
            
            <div style={{padding:'10px',display:'flex',flexDirection:'column',backgroundColor:'#FFFFFF',maxWidth:'1600px',flex:1}}>

                
                
                <div style={{border:'1px solid #F1F1F1',backgroundColor:'#F4F4F4',marginBottom:'5px',display:'flex',flexDirection:'column',borderRadius:'1px'}}>

                
                <div>
                    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start',paddingRight:'15px',padding:'10px 0px 0px 20px'}}>
                        <div style={{fontSize:'31px',fontFamily:"'Roboto', sans-serif",fontWeight:300,padding:'5px 0px 0px'}}>
                            Robust Design
                        </div>
                        <div style={{fontSize:'13px',fontFamily:"'Roboto', sans-serif",fontWeight:500,padding:'5px 0px 10px 30px',opacity:0.7}}>
                            Carefully built out of simplicity.
                        </div>
                    </div>
                </div>



                <div style={{display:'flex',justifyContent:'center',alignItems:'center',flex:1,overflow:'hidden'}}>
                    <img src={boxFrontFacingPowerBtn} style={{width:'120%'}}/>
                </div>

                
                <div style={{display:'flex',flexDirection:'row',backgroundColor:'#f4f4f4'}}>
                    
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',flex:1,margin:'5px 2.5px 5px 5px',backgroundColor:'#FFFFFF',borderRadius:2}}>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',overflow:'hidden'}}>
                            <img src={boxSided} style={{width:'100%',}}/>
                        </div>
                    </div>


                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',flex:1,margin:'5px 5px 5px 2.5px',backgroundColor:'#FFFFFF',borderRadius:2}}>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',}}>
                            <img src={boxBackSidedNew} style={{width:'100%',}}/>
                        </div>
                    </div>
                        
                </div>

                </div>
 


            </div>
        </div>
    )

}




export function NewStageMobile(){
    return(
        <div className="power_rating-carrier-m" style={{}}>
            
            <div 
                style={{
                    fontFamily:"Roboto",fontWeight:300,padding:'0px',
                    display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'flex-end'}}>
                <div style={{fontSize:80}}>O</div>
                <div style={{fontSize:24,marginBottom:'30px',fontWeight:100,fontFamily:"'Roboto',san-serif"}}>ne device to power them all</div>
            </div>

            <div style={{padding:5,paddingBottom:50,marginTop:-20}}>
                <img src={boxLoadSupport} style={{width:'100%',}}/>
            </div>

            {/* <div style={{fontSize:10,color:'#FFFFFF',padding:'10px 5px 5px 10px'}}>With a single powerbox you can power all this load at once</div> */}

        </div>
    )
}



export function NewStagePc(){
    return(
        <div className="power_rating-carrier-pc" style={{}}>
            <div style={{padding:'0px',maxWidth:'1600px',flex:1,margin:'-20px auto 0px'}}>

                <div style={{fontFamily:"Roboto",fontWeight:300,padding:'0px',display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'flex-end',paddingLeft:40}}>
                    <div style={{fontSize:120}}>O</div>
                    <div style={{fontSize:50,marginBottom:'41px',fontWeight:100,fontFamily:"'Roboto',san-serif"}}>ne device to power them all</div>
                </div>

                <div style={{padding:5,paddingBottom:50,display:'flex',justifyContent:'center',alignItems:'center',marginTop:-20}}>
                    <img src={boxLoadSupport} style={{width:'53%',}}/>
                </div>
                
            </div>
            
        </div>
    )
}




export function TestimonyFuncMobile(){
    return(
        <div className="testimony_wrapper_m" style={{}}>

            <div>
                <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start',padding:'30px 10px 30px'}}>
                    <div style={{fontSize:'27px',fontFamily:"'Roboto', sans-serif",fontWeight:300,padding:'5px 0px 0px'}}>
                        What Our Customers Say
                    </div>
                </div>
            </div>


            
            {/* bubble starts */}
            <div style={{}} className='testimony_wrapper'>
                <div className='bubble_profile_carrier'>
                    <div className='bubble_profile_wrapper'>
                        <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <img src={require('../../images/new_images/chat.png')} style={{width:25,height:25}}/>
                        </div>
                    </div>
                    <div className='bubble_profile_name'>Halidu - Baruten, Kwara State</div>
                </div>

                <div style={{}} className='bubble_mgs_wrapper'>
                I love the ease of use of the Powerbox, my family use it daily to power the whole house. Thanks to Powerbox my children can
study at night without any challenge. Thankfully, I can also plug-in appliances into any wall socket in the house which is very convienent.
                </div>
            </div>
            {/* bubble ends */}


            {/* bubble starts */}
            <div style={{}} className='testimony_wrapper'>
                <div className='bubble_profile_carrier'>
                    <div className='bubble_profile_wrapper'>
                        <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <img src={require('../../images/new_images/chat.png')} style={{width:25,height:25}}/>
                        </div>
                    </div>
                    <div className='bubble_profile_name'>Jamil - Kano, Kano State</div>
                </div>

                <div style={{}} className='bubble_mgs_wrapper'>
                I do not worry about coming back home from work and not having PHCN power, I just turn on my powerbox all through the Night.
I had to introduce Powerbox to a co-worker who is currently also a customer.
                </div>
            </div>
            {/* bubble ends */}


            {/* bubble starts */}
            <div style={{}} className='testimony_wrapper'>
                <div className='bubble_profile_carrier'>
                    <div className='bubble_profile_wrapper'>
                        <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <img src={require('../../images/new_images/chat.png')} style={{width:25,height:25}}/>
                        </div>
                    </div>
                    <div className='bubble_profile_name'>Oyindamola - Ogere, Ogun State</div>
                </div>

                <div style={{}} className='bubble_mgs_wrapper'>
                I am a software programer that relies on the Powerbox for on-demand power for my workstation to meet my work deadlines, anytime anyday.
Great product.
                </div>
            </div>
            {/* bubble ends */}





        </div>
    )
}








export function TestimonyFuncPc(){
    return(
        <div className="testimony_wrapper_pc" style={{}}>
            <div style={{margin:'0px auto 0px'}}>

                    <div>
                        <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start',padding:'30px 10px 30px'}}>
                            <div style={{fontSize:'27px',fontFamily:"'Roboto', sans-serif",fontWeight:300,padding:'5px 0px 0px'}}>
                                What Our Customers Say
                            </div>
                        </div>
                    </div>

                    <div style={{display:'flex',flexDirection:'row',paddingTop:'20px',paddingBottom:'20px'}}>
                            
                            {/* bubble starts */}
                            <div style={{}} className='testimony_wrapper_bubble_pc'>
                                <div className='bubble_profile_carrier'>
                                    <div className='bubble_profile_wrapper'>
                                        <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <img src={require('../../images/new_images/chat.png')} style={{width:25,height:25}}/>
                                        </div>
                                    </div>
                                    <div className='bubble_profile_name'>Halidu - Baruten, Kwara State</div>
                                </div>

                                <div style={{}} className='bubble_mgs_wrapper'>
                                I love the ease of use of the Powerbox, my family use it daily to power the whole house. Thanks to Powerbox my children can
                study at night without any challenge. Thankfully, I can also plug-in appliances into any wall socket in the house which is very convienent.
                                </div>
                            </div>
                            {/* bubble ends */}


                            {/* bubble starts */}
                            <div style={{}} className='testimony_wrapper_bubble_pc'>
                                <div className='bubble_profile_carrier'>
                                    <div className='bubble_profile_wrapper'>
                                        <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <img src={require('../../images/new_images/chat.png')} style={{width:25,height:25}}/>
                                        </div>
                                    </div>
                                    <div className='bubble_profile_name'>Jamil - Kano, Kano State</div>
                                </div>

                                <div style={{}} className='bubble_mgs_wrapper'>
                                I do not worry about coming back home from work and not having PHCN power, I just turn on my powerbox all through the Night.
                I had to introduce Powerbox to a co-worker who is currently also a customer.
                                </div>
                            </div>
                            {/* bubble ends */}


                            {/* bubble starts */}
                            <div style={{}} className='testimony_wrapper_bubble_pc'>
                                <div className='bubble_profile_carrier'>
                                    <div className='bubble_profile_wrapper'>
                                        <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <img src={require('../../images/new_images/chat.png')} style={{width:25,height:25}}/>
                                        </div>
                                    </div>
                                    <div className='bubble_profile_name'>Oyindamola - Ogere, Ogun State</div>
                                </div>

                                <div style={{}} className='bubble_mgs_wrapper'>
                                I am a software programer that relies on the Powerbox for on-demand power for my workstation to meet my work deadlines, anytime anyday.
                Great product.
                                </div>
                            </div>
                            {/* bubble ends */}

                    </div>




            </div>
        </div>
    )
}






export function AwardFuncPc(){
    return(
        <div className="award_wrapper_pc" style={{}}>
            <div style={{margin:'0px auto 0px'}}>

                    <div>
                        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',padding:'30px 10px 30px'}}>
                            <div style={{fontSize:'55px',fontFamily:"'Roboto', sans-serif",fontWeight:100,padding:'5px 100px 0px',lineHeight:'70px',color:'#FFFFFF'}}>
                                The Most Innovative African Clean-energy Company 2022 
                            </div>
                        </div>
                    </div>




                    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:40,marginBottom:80}}>
                       
                        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginRight:'100px'}}>
                            <div style={{color:'#FFFFFF'}}>
                                <img src={lhgp_Logo} style={{width:'110px',height:'110px',borderRadius:'150px',overflow:'hidden'}}/>
                            </div>

                            <div style={{color:'#FFFFFF',paddingTop:10,fontWeight:'100'}}>
                                Lion's Head Global Partners (UK)
                            </div>

                        </div>

                        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginLeft:'100px'}}>
                            <div style={{color:'#FFFFFF'}}>
                                <img src={afritech_Logo} style={{width:'110px',height:'110px',borderRadius:'150px',overflow:'hidden'}}/>
                            </div>

                            <div style={{color:'#FFFFFF',paddingTop:10,fontWeight:'100'}}>
                            AfriTech XYZ 
                            </div>

                        </div>

                    </div>

                   




            </div>
        </div>
    )
}



export function AwardFuncMobile(){
    return(
        <div className="award_wrapper_pc" style={{}}>
            <div style={{width:'100%'}}>

                    <div>
                        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',padding:'20px 10px 30px'}}>
                            <div style={{fontSize:'40px',fontFamily:"'Roboto', sans-serif",fontWeight:300,color:'#FFFFFF'}}>
                                The Most Innovative African Clean-energy Company 2022 
                            </div>
                        </div>
                    </div>




                    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:20,marginBottom:40}}>
                       
                        <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center',flex:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <div style={{color:'#FFFFFF'}}>
                                <img src={lhgp_Logo} style={{width:'70px',height:'70px',borderRadius:'150px',overflow:'hidden'}}/>
                            </div>

                            <div style={{color:'#FFFFFF',paddingTop:10,fontWeight:'100',fontSize:12,maxWidth:150,textAlign:'center'}}>
                                Lion's Head Global Partners (UK)
                            </div>

                        </div>

                        <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center',flex:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <div style={{color:'#FFFFFF'}}>
                                <img src={afritech_Logo} style={{width:'70px',height:'70px',borderRadius:'150px',overflow:'hidden'}}/>
                            </div>

                            <div style={{color:'#FFFFFF',paddingTop:10,fontWeight:'100',fontSize:12,maxWidth:150,textAlign:'center'}}>
                            AfriTech XYZ 
                            </div>

                        </div>

                    </div>

                   




            </div>
        </div>
    )
}






export function FourthStagePc(){

    const [showContact, setShowContact] = useState(false);

    function processPurchase(){
        setShowContact(true);
    }


    return(
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'row'}}>
            <div style={{padding:'10px',display:'flex',flexDirection:'row',backgroundColor:'#FFFFFF',maxWidth:'1600px',flex:1}}>

            <div style={{padding:'10px',display:'flex',flexDirection:'row',backgroundColor:'#F4F4F4',flex:1}}>
                
                <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',paddingTop:'30px'}}>
                   
                    <div style={{padding:'0px 0px 22px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <div style={{paddingRight:'10px'}}><Icons icon="ok" size="25" color="#00972D"/></div>
                        <div style={{fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:500,}}>Continous Day Time Use</div>
                    </div>

                    <div style={{padding:'0px 0px 22px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <div style={{paddingRight:'10px'}}><Icons icon="ok" size="25" color="#00972D"/></div>
                        <div style={{fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:500,}}>Install in few minutes</div>
                    </div>

                    <div style={{padding:'0px 0px 22px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <div style={{paddingRight:'10px'}}><Icons icon="ok" size="25" color="#00972D"/></div>
                        <div style={{fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:500,}}>Portable and Movable</div>
                    </div>

                    <div style={{padding:'0px 0px 22px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <div style={{paddingRight:'10px'}}><Icons icon="ok" size="25" color="#00972D"/></div>
                        <div style={{fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:500,}}>Long lasting Energy Storage</div>
                    </div>

                    <div style={{padding:'0px 0px 22px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <div style={{paddingRight:'10px'}}><Icons icon="ok" size="25" color="#00972D"/></div>
                        <div style={{fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:500,}}>24/7 Customer Service</div>
                    </div>

                </div>





                
                <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                    <div style={{fontSize:'50px',fontFamily:"'Roboto', sans-serif",fontWeight:600}}>Powerbox Pro</div>

                    {(!showContact)?
                    <CSSTransitionGroup
                    transitionName="fadeout"
                    transitionAppear={true}
                    transitionAppearTimeout={500}
                    transitionEnter={false}
                    transitionLeave={true}
                    >
                        <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            <div style={{fontSize:'17px',fontFamily:"'Roboto', sans-serif",fontWeight:300}}>Comes with Two Solar Panels</div>
                            {/* <div style={{fontSize:'40px',fontFamily:"'Roboto', sans-serif",fontWeight:400,padding:'10px 0px 0px',display:'flex',flexDirection:'row'}}>
                                <div style={{marginBottom:'0px'}}>
                                    <div style={{marginBottom:'10px'}}>
                                        <Icons icon="naira" size="37" color="#000000"/>
                                    </div>
                                </div>
                                <div style={{}}>150,000</div>
                            </div> */}
                            <div
                                onClick={processPurchase} 
                                style={{padding:'15px 50px 12px',backgroundColor:'#FFFFFF',borderRadius:'5px',cursor:'pointer',marginTop:20}} className="buynowBtn">Buy Now</div>
                        </div>
                    </CSSTransitionGroup>
                    :undefined}


                   {(showContact)?     
                    <CSSTransitionGroup
                        transitionName="fade"
                        transitionAppear={true}
                        transitionAppearTimeout={1300}
                        transitionEnter={false}
                        transitionLeave={false}
                        >
                        <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',paddingTop:'15px'}}>
                            <div style={{fontSize:'16px',fontFamily:"'Roboto', sans-serif",fontWeight:400,}}>Chat with us on whatsapp </div>
                            <div style={{fontSize:'35px',fontFamily:"'Roboto', sans-serif",fontWeight:400,}}>09070811425</div>
                        </div>
                    </CSSTransitionGroup>
                    :undefined}

                    



                </div>




            </div>
        </div>
            
        </div>
    )}





    export function FourthStageMobile(){

        const [showContact, setShowContact] = useState(false);
    
        function processPurchase(){
            setShowContact(true);
        }
    
    
        return(
            <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'row'}}>
                <div style={{padding:'10px',display:'flex',flexDirection:'row',backgroundColor:'#FFFFFF',maxWidth:'1600px',flex:1}}>
    
                <div style={{padding:'10px',display:'flex',flexDirection:'column',backgroundColor:'#F4F4F4',flex:1}}>
                    
                    <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',paddingTop:'30px'}}>
                       
                        <div style={{padding:'0px 0px 22px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            <div style={{paddingRight:'10px'}}><Icons icon="ok" size="25" color="#00972D"/></div>
                            <div style={{fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:500,}}>Continous Day Time Use</div>
                        </div>
    
                        <div style={{padding:'0px 0px 22px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            <div style={{paddingRight:'10px'}}><Icons icon="ok" size="25" color="#00972D"/></div>
                            <div style={{fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:500,}}>Install in few minutes</div>
                        </div>
    
                        <div style={{padding:'0px 0px 22px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            <div style={{paddingRight:'10px'}}><Icons icon="ok" size="25" color="#00972D"/></div>
                            <div style={{fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:500,}}>Portable and Movable</div>
                        </div>
    
                        <div style={{padding:'0px 0px 22px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            <div style={{paddingRight:'10px'}}><Icons icon="ok" size="25" color="#00972D"/></div>
                            <div style={{fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:500,}}>Long lasting Energy Storage</div>
                        </div>
    
                        <div style={{padding:'0px 0px 22px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            <div style={{paddingRight:'10px'}}><Icons icon="ok" size="25" color="#00972D"/></div>
                            <div style={{fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:500,}}>24/7 Customer Service</div>
                        </div>
    
                    </div>
    
    
    
    
    
                    
                    <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',paddingTop:'20px'}}>
                        <div style={{fontSize:'40px',fontFamily:"'Roboto', sans-serif",fontWeight:600}}>Powerbox Pro</div>
    
                        {(!showContact)?
                        <CSSTransitionGroup
                        transitionName="fadeout"
                        transitionAppear={true}
                        transitionAppearTimeout={500}
                        transitionEnter={false}
                        transitionLeave={true}
                        >
                            <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                <div style={{fontSize:'14px',fontFamily:"'Roboto', sans-serif",fontWeight:300}}>Comes with Two Solar Panels</div>
                                {/* <div style={{fontSize:'40px',fontFamily:"'Roboto', sans-serif",fontWeight:400,padding:'10px 0px 0px',display:'flex',flexDirection:'row'}}>
                                    <div style={{marginBottom:'0px'}}>
                                        <div style={{marginBottom:'10px'}}>
                                            <Icons icon="naira" size="37" color="#000000"/>
                                        </div>
                                    </div>
                                    <div style={{}}>150,000</div>
                                </div> */}
                                <div
                                    onClick={processPurchase} 
                                    style={{padding:'15px 50px 12px',backgroundColor:'#FFFFFF',borderRadius:'5px',cursor:'pointer',marginTop:20}} className="buynowBtn">Buy Now</div>

                            </div>
                        </CSSTransitionGroup>
                        :undefined}
    
    
                       {(showContact)?     
                        <CSSTransitionGroup
                            transitionName="fade"
                            transitionAppear={true}
                            transitionAppearTimeout={1300}
                            transitionEnter={false}
                            transitionLeave={false}
                            >
                            <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',paddingTop:'15px'}}>
                                <div style={{fontSize:'16px',fontFamily:"'Roboto', sans-serif",fontWeight:400,}}>Chat with us on whatsapp </div>
                                <div style={{fontSize:'35px',fontFamily:"'Roboto', sans-serif",fontWeight:400,}}>09070811425</div>
                            </div>
                        </CSSTransitionGroup>
                        :undefined}
    
                        
    
    
    
                    </div>
    
    
    
    
                </div>
            </div>
                
            </div>
        )}



//     export function FifthStagePc(){
//         return(
//             <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'row'}}>
//                 <div style={{padding:'10px',display:'flex',flexDirection:'row',backgroundColor:'#FFFFFF',maxWidth:'1600px',flex:1}}>
//                     <div style={{display:'flex',flexDirection:'column',flex:1,padding:'20px 10px 100px'}}>

//                         <div style={{display:'flex',flexDirection:'row'}}>

//                             <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
//                                 <div style={{fontSize:'13px',fontFamily:"'Roboto', sans-serif",fontWeight:600,color:'#000000',opacity:0.5}}>Whatsapp</div>
//                                 <div style={{fontSize:'20px',fontFamily:"'Roboto', sans-serif",fontWeight:200,color:'#000000'}}>09070811425</div>
//                             </div>

//                             <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
//                                 <div style={{fontSize:'13px',fontFamily:"'Roboto', sans-serif",fontWeight:600,color:'#000000',opacity:0.5}}>Email</div>
//                                 <div style={{fontSize:'20px',fontFamily:"'Roboto', sans-serif",fontWeight:200,color:'#000000'}}>support@powerbox.com.ng</div>
//                             </div>

//                             <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
//                                 <div style={{fontSize:'13px',fontFamily:"'Roboto', sans-serif",fontWeight:600,color:'#000000',opacity:0.5}}>Twitter</div>
//                                 <div style={{fontSize:'20px',fontFamily:"'Roboto', sans-serif",fontWeight:200,color:'#000000'}}>@powerboxenergy</div>
//                             </div>

//                         </div>
                        
//                         {/* <div style={{display:'flex',justifyContent:'center',alignItems:'center',padding:'20px 0px 5px',fontSize:'13px',fontFamily:"'Roboto', sans-serif",fontWeight:300,color:'#333333'}}>Designed and manufactured by</div>
//                         <div style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:'13px',fontFamily:"'Roboto', sans-serif",fontWeight:600,color:'#333333'}}>Bitprime Interactive Limited</div>
//                         <div style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:'13px',fontFamily:"'Roboto', sans-serif",fontWeight:300,color:'#333333'}}>All rights reserved</div>
//  */}

//                         {/* <div style={{display:'flex',justifyContent:'center',alignItems:'center',padding:'20px 0px 5px',fontSize:'13px',fontFamily:"'Roboto', sans-serif",fontWeight:300,color:'#333333'}}>Designed in Finland</div> */}
//                         {/* <div style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:'13px',fontFamily:"'Roboto', sans-serif",fontWeight:200,color:'#333333'}}>Assembled by Bitprime Interactive Limited</div> */}
//                         <div style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:'13px',fontFamily:"'Roboto', sans-serif",fontWeight:300,color:'#333333'}}>All rights reserved</div>


//                     </div>
//                 </div>
//             </div>  
//         )  
//     }







    export function FifthStageMobile(){
        return(
            <div className="footer_wrapper">

                <div style={{}} className='footer_logo_holder'>
                    <div style={{}} className='footer_logo_carrier'>
                        <Icons icon="logo" size="160" color="#399947"/>
                    </div>
                </div>


                <div style={{padding:'15px 20px 10px'}}>
                    <div style={{}} className='footer_h1'>Our Products</div>
                    <div style={{}} className='footer_h2'>Powerbox Pro</div>
                    <div style={{}} className='footer_h2'>Powerbox SE</div>
                </div>


                {/* <div style={{padding:'0px 20px 10px'}}>
                    <div style={{}} className='footer_h1'>Help</div>
                    <div style={{}} className='footer_h2'>FAQs</div>
                    <div style={{}} className='footer_h2'>Contact Us</div>
                </div> */}

                <div style={{padding:'0px 20px 10px'}}>
                    <div style={{}} className='footer_h1'>Twitter</div>
                    <div style={{}} className='footer_h2'>@powerboxEnergy</div>
                </div>

                <div style={{padding:'0px 20px 10px'}}>
                    <div style={{}} className='footer_h1'>Email</div>
                    <div style={{}} className='footer_h2'>Support@powerbox.com.ng</div>
                </div>

                <div style={{padding:'0px 20px 20px'}}>
                    <div style={{}} className='footer_h1'>Legal</div>
                    <div style={{}} className='footer_h2'>Privacy Policy</div>
                </div>


                <div className='last_footer_wrapper'>
                    The Powerbox logo and technologies are trademark properties of Bitprime LLC and its regional subsidiaries. 
                    <div style={{padding:'0px 20px 0px'}}>
                        &#169; 2021 Bitprime LLC. All Rights Reserved.
                    </div> 
                </div>
                


                {/* the footer of the pagess */}
            </div>  
        )  
    }






    export function FifthStagePc(){
        return(
            <div className="footer_wrapper">


                <div style={{maxWidth:'1600px',margin:'30px auto 30px',display:'flex',flexDirection:'row',padding:'20px 15px 50px'}}>




                    <div style={{flex:1.1,alignItems:'center',display:'flex',flexDirection:'column'}}>
                        <div style={{height:'110px',overflow:'hidden',marginTop:'-65px'}} >
                            <Icons icon="logo2" size="160" color="#399947"/>
                        </div>
                    </div>


                    <div style={{padding:'0px 20px 10px',flex:1}}>
                        <div style={{}} className='footer_h1'>Our Products</div>
                        <div style={{}} className='footer_h2'>Powerbox Pro</div>
                        <div style={{}} className='footer_h2'>Powerbox SE</div>
                    </div>


                    <div style={{padding:'0px 20px 10px',flex:1}}>
                        <div style={{}} className='footer_h1'>Twitter</div>
                        <div style={{}} className='footer_h2'>@powerboxEnergy</div>
                    </div>

                    <div style={{padding:'0px 20px 10px',flex:1}}>
                        <div style={{}} className='footer_h1'>Email</div>
                        <div style={{}} className='footer_h2'>Support@powerbox.com.ng</div>
                    </div>

                    <div style={{padding:'0px 20px 20px',flex:1}}>
                        <div style={{}} className='footer_h1'>Legal</div>
                        <div style={{}} className='footer_h2'>Privacy Policy</div>
                    </div>


                    


                    
                </div>

                    <div className='last_footer_wrapper_pc'>
                        The Powerbox logo and technologies are trademark properties of Bitprime LLC and its regional subsidiaries. 
                        <div style={{padding:'0px 20px 0px'}}>
                            &#169; 2021 Bitprime LLC. All Rights Reserved.
                        </div> 
                    </div>

                
            </div>  
        )  
    }









export default function Home(){

    useEffect(() => {
        ReactGA.pageview(window.location);
    },[]);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 800px)'
      })


    return(
    <div>
        <Header/>
        <TopStage/>

        

        {(isDesktopOrLaptop)?
        <SecondStagePc/>:
        <SecondStageMobile/>}

        {(isDesktopOrLaptop)?
        <NewStagePc/>:
        <NewStageMobile/>}
        
       
        {(isDesktopOrLaptop)?
        <ThirdStagePc/>:
        <ThirdStageMobile/>}

        {(isDesktopOrLaptop)?
        <TestimonyFuncPc/>:
        <TestimonyFuncMobile/>}

        {(isDesktopOrLaptop)?
        <AwardFuncPc/>:
        <AwardFuncMobile/>}




        {(isDesktopOrLaptop)?
        <FourthStagePc />:
        <FourthStageMobile/>
        }

        {(isDesktopOrLaptop)?
        <FifthStagePc/>:
        <FifthStageMobile/>
        }
    </div>)
}